import {Container} from "@mui/material";
import Head from "../components/Head";
import React from "react";

const TermsAndConditions = () => (
    <Container maxWidth="sm" style={{marginTop: '20px'}}>
        <Head title={'xl | Offer'}/>
        <h2 className={'mb-3 text-center'}>
            Оферта
        </h2>
        <h5 className={'mb-2 mt-3'}>
            Публичная оферта на предоставление доступа к программному обеспечению xlartas
        </h5>
        <h4 className={'mb-2 mt-3'}>
            1. Общие положения
        </h4>
        <p className={'mb-1'}>
            1.1. Настоящий документ "Оферта" представляет собой предложение ИП "Толпегин Никита Дмитриевич" (далее —
            "Исполнитель"), адресованное неопределенному кругу лиц(далее — "Пользователь"), приобрести лицензию на
            использование программного обеспечения представленного в разделе software веб-сайта xlartas.ru(далее —
            "Программное Обеспечение" или "ПО") на условиях, изложенных в данном документе.
        </p>
        <p>
            1.2. Полным и безоговорочным принятием данной оферты (акцептом) считается факт оплаты Лицензии в
            соответствии с условиями настоящей оферты.
        </p>
        <h4 className={'mb-2 mt-3'}>
            2. Предмет оферты
        </h4>
        <p className={'mb-1'}>
            2.1. Исполнитель предоставляет Пользователю право использования ПО на основании
            неисключительной лицензии.
        </p>
        <p>
            2.2. Право использования ПО предоставляется на условиях подписки, срок действия
            которой начинается с момента активации и продолжается в течение оплаченного периода.
        </p>
        <h4 className={'mb-2 mt-3'}>
            3. Права и обязанности сторон
        </h4>
        <p className={'mb-1'}>
            3.1. Исполнитель обязуется предоставить Пользователю доступ к оплаченному ПО в течение 24 часов
            после поступления оплаты, иначе Пользователь вправе запросить возврат денежных средств.
        </p>
        <p className={'mb-1'}>
            3.2. Пользователь обязуется использовать ПО строго в соответствии с его функциональным
            назначением и не распространять копии программы третьим лицам.
        </p>
        <p>
            3.3. Исполнитель имеет право вносить изменения в состав и функциональность ПО,
            не уведомляя об этом Пользователя лично. Любая информация об изменениях/обновлениях ПО
            находится в карточке конкретного ПО на странице Software сайта xlartas.ru.
        </p>
        <h4 className={'mb-2 mt-3'}>
            4. Условия оплаты
        </h4>
        <p className={'mb-1'}>
            4.1. Актуальная стоимость подписки на использование ПО указана в карточке ПО.
        </p>
        <p>
            4.2. Оплата подписки производится Пользователем единоразово без рекуррентных платежей.
        </p>
        <h4 className={'mb-2 mt-3'}>
            5. Ответственность сторон и ограничения
        </h4>
        <p className={'mb-1'}>
            5.1. Исполнитель не несет ответственность за прямые или косвенные убытки Пользователя, возникшие в
            результате использования или невозможности использования Программного Обеспечения.
        </p>
        <p>
            5.2. Исполнитель не гарантирует бесперебойную работу Программного Обеспечения в случае возникновения
            обстоятельств непреодолимой силы, а также действий третьих лиц.
        </p>
        <h4 className={'mb-2 mt-3'}>
            6. Заключительные положения
        </h4>
        <p className={'mb-1'}>
            6.1. Все споры и разногласия, возникающие в связи с выполнением сторонами обязательств по настоящей оферте,
            решаются путем переговоров.
        </p>
        <p>
            6.2. На отношения между Исполнителем и Пользователем применимо законодательство Российской Федерации.
        </p>
        <h4 className={'mb-2 mt-3'}>
            Дата публикации: 08.05.2024
        </h4>
    </Container>
);

export default TermsAndConditions;