import {Container} from "@mui/material";
import Head from "../components/Head";
import React from "react";

const TermsAndConditions = () => (
    <Container maxWidth="sm" style={{marginTop: '20px'}}>
        <Head title={'xl | Terms And Conditions'}/>
        <h2 className={'mb-3 text-center'}>
            Условия использования
        </h2>
        <h4 className={'mb-2 mt-3'}>Права интеллектуальной собственности</h4>
        <p>
            xlartas владеет всеми правами интеллектуальной собственности
            и материалами, содержащимися на этом веб-сайте.
        </p>
        <h4 className={'mb-2 mt-3'}>Авторские права</h4>
        <p>
            Программное обеспечение, логотипы(сайта и продуктов),
            код продукта, названия являются собственностью xlartas
            и защищены международными законами об авторском праве.
        </p>
        <h4 className={'mb-2 mt-3'}>Ваша учетная запись пользователя</h4>
        <p>
            Если вы используете этот продукт, вы несете ответственность за сохранение
            конфиденциальности вашей учетной записи и пароль и для ограничения доступа к
            вашему компьютеру, и вы соглашаетесь принять на себя ответственность за все
            действия, которые происходят под вашей учетной записью или паролем. Если вам
            меньше 18 лет, вы можете использовать наш продукт только с участие родителя
            или опекуна. xlartas и ее партнеры оставляют за собой право отказать в обслуживании,
            закрывать учетные записи, удалять или редактировать контент или отменять заказы
            по своему усмотрению.
        </p>
        <h4 className={'mb-2 mt-3'} style={{lineHeight: 1.3}}>
            Положения и условия использования программного обеспечения xlartas
        </h4>
        <p>
            Оплачивая подписку на продукт(software) на ваш аккаунт зачисляется выбранное при оплате
            количество часов(недель, месяцев и т.п.) для использования выбранного товара.
            Для скачивания продукта перейдите на страницу Software и нажмите на кнопку скачивания.
            Для входа в программу используется ваш логин и секретный ключ(secret key), вы их можете найти
            на главной страницы, но при условии, что вы вошли в аккаунт.
        </p>
        <h4 className={'mb-2 mt-3'}>Условия возврата и обмена покупки</h4>
        <p>
            Причиной возврата денежных средств за приобретенную подписку на программный продукт
            может служить неработоспособность продукта более 1 дня в любой период оплаченной подписки.
            Резерв в 1 день нужен для возможных технических работ.
            Подписка на продукт(программное обеспечение) обмену не подлежит.
            Для возврата свяжитесь с нами по контактным данным в разделе `О нас`(About)
        </p>
    </Container>
);

export default TermsAndConditions;