import {createTheme} from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#000000',
            light: '#757575',
            dark: '#292929',
        },
        secondary: {
            main: '#dc004e',
            light: '#ff616f',
            dark: '#9a0036',
        },
        text: {
            primary05: 'rgba(0,0,0,.05) !important',
            primary10: 'rgba(0,0,0,.1) !important',
            primary15: 'rgba(0,0,0,.15) !important',
            primary20: 'rgba(0,0,0,.2) !important',
            primary25: 'rgba(0,0,0,.25) !important',
            primary30: 'rgba(0,0,0,.3) !important',
            primary35: 'rgba(0,0,0,.35) !important',
            primary40: 'rgba(0,0,0,.4) !important',
            primary45: 'rgba(0,0,0,.45) !important',
            primary50: 'rgba(0,0,0,.5) !important',
            primary55: 'rgba(0,0,0,.55) !important',
            primary60: 'rgba(0,0,0,.6) !important',
            primary65: 'rgba(0,0,0,.65) !important',
            primary70: 'rgba(0,0,0,.7) !important',
            primary75: 'rgba(0,0,0,.75) !important',
            primary80: 'rgba(0,0,0,.8) !important',
            primary85: 'rgba(0,0,0,.85) !important',
            primary90: 'rgba(0,0,0,.9) !important',
            primary95: 'rgba(0,0,0,.95) !important',
            primary: '#000 !important',

            contrast05: 'rgba(255,255,255,.05) !important',
            contrast10: 'rgba(255,255,255,.1) !important',
            contrast15: 'rgba(255,255,255,.15) !important',
            contrast20: 'rgba(255,255,255,.2) !important',
            contrast25: 'rgba(255,255,255,.25) !important',
            contrast30: 'rgba(255,255,255,.3) !important',
            contrast35: 'rgba(255,255,255,.35) !important',
            contrast40: 'rgba(255,255,255,.4) !important',
            contrast45: 'rgba(255,255,255,.45) !important',
            contrast50: 'rgba(255,255,255,.5) !important',
            contrast55: 'rgba(255,255,255,.55) !important',
            contrast60: 'rgba(255,255,255,.6) !important',
            contrast65: 'rgba(255,255,255,.65) !important',
            contrast70: 'rgba(255,255,255,.7) !important',
            contrast75: 'rgba(255,255,255,.75) !important',
            contrast80: 'rgba(255,255,255,.8) !important',
            contrast85: 'rgba(255,255,255,.85) !important',
            contrast90: 'rgba(255,255,255,.9) !important',
            contrast95: 'rgba(255,255,255,.95) !important',
            contrast: '#fff !important',

            secondary: '#757575',
            disabled: '#bdbdbd',
            hint: '#9e9e9e',
            contrastText: '#fff',
        },
        bg: {
            primary05: 'rgba(255,255,255,.05) !important',
            primary10: 'rgba(255,255,255,.1) !important',
            primary15: 'rgba(255,255,255,.15) !important',
            primary20: 'rgba(255,255,255,.2) !important',
            primary25: 'rgba(255,255,255,.25) !important',
            primary30: 'rgba(255,255,255,.3) !important',
            primary35: 'rgba(255,255,255,.35) !important',
            primary40: 'rgba(255,255,255,.4) !important',
            primary45: 'rgba(255,255,255,.45) !important',
            primary50: 'rgba(255,255,255,.5) !important',
            primary55: 'rgba(255,255,255,.55) !important',
            primary60: 'rgba(255,255,255,.6) !important',
            primary65: 'rgba(255,255,255,.65) !important',
            primary70: 'rgba(255,255,255,.7) !important',
            primary75: 'rgba(255,255,255,.75) !important',
            primary80: 'rgba(255,255,255,.8) !important',
            primary85: 'rgba(255,255,255,.85) !important',
            primary90: 'rgba(255,255,255,.9) !important',
            primary95: 'rgba(255,255,255,.95) !important',
            primary: '#fff !important',

            contrast05: 'rgba(0,0,0,.05) !important',
            contrast10: 'rgba(0,0,0,.1) !important',
            contrast15: 'rgba(0,0,0,.15) !important',
            contrast20: 'rgba(0,0,0,.2) !important',
            contrast25: 'rgba(0,0,0,.25) !important',
            contrast30: 'rgba(0,0,0,.3) !important',
            contrast35: 'rgba(0,0,0,.35) !important',
            contrast40: 'rgba(0,0,0,.4) !important',
            contrast45: 'rgba(0,0,0,.45) !important',
            contrast50: 'rgba(0,0,0,.5) !important',
            contrast55: 'rgba(0,0,0,.55) !important',
            contrast60: 'rgba(0,0,0,.6) !important',
            contrast65: 'rgba(0,0,0,.65) !important',
            contrast70: 'rgba(0,0,0,.7) !important',
            contrast75: 'rgba(0,0,0,.75) !important',
            contrast80: 'rgba(0,0,0,.8) !important',
            contrast85: 'rgba(0,0,0,.85) !important',
            contrast90: 'rgba(0,0,0,.9) !important',
            contrast95: 'rgba(0,0,0,.95) !important',
            contrast: '#000 !important',

            secondary: '#ccc',
            tertiary: '#757575',
            quaternary: '#171717',
        },
        shadow: {
            // Outer white .6
            XXSO06: '0 0 .05em rgba(255, 255, 255, .6)',
            XSO06: '0 0 .1em rgba(255, 255, 255, .6)',
            SO06: '0 0 .2em rgba(255, 255, 255, .6)',
            MO06: '0 0 .5em rgba(255, 255, 255, .6)',
            LO06: '0 0 .7em rgba(255, 255, 255, .6)',
            XLO06: '0 0 1em rgba(255, 255, 255, .6)',
            XXLO06: '0 0 1.7em rgba(255, 255, 255, .6)',
            XXXLO06: '0 0 3em rgba(255, 255, 255, .6)',
            // Outer white .4
            XXSO04: '0 0 .05em rgba(255, 255, 255, .4)',
            XSO04: '0 0 .1em rgba(255, 255, 255, .4)',
            SO04: '0 0 .2em rgba(255, 255, 255, .4)',
            MO04: '0 0 .5em rgba(255, 255, 255, .4)',
            LO04: '0 0 .7em rgba(255, 255, 255, .4)',
            XLO04: '0 0 1em rgba(255, 255, 255, .4)',
            XXLO04: '0 0 1.7em rgba(255, 255, 255, .4)',
            XXXLO04: '0 0 3em rgba(255, 255, 255, .4)',
            // Outer white .2
            XXSO02: '0 0 .05em rgba(255, 255, 255, .2)',
            XSO02: '0 0 .1em rgba(255, 255, 255, .2)',
            SO02: '0 0 .2em rgba(255, 255, 255, .2)',
            MO02: '0 0 .5em rgba(255, 255, 255, .2)',
            LO02: '0 0 .7em rgba(255, 255, 255, .2)',
            XLO02: '0 0 1em rgba(255, 255, 255, .2)',
            XXLO02: '0 0 1.7em rgba(255, 255, 255, .2)',
            XXXLO02: '0 0 3em rgba(255, 255, 255, .2)',
            // Inner white .6
            XXSI06: 'inset 0 0 .05em rgba(255, 255, 255, .6)',
            XSI06: 'inset 0 0 .1em rgba(255, 255, 255, .6)',
            SI06: 'inset 0 0 .2em rgba(255, 255, 255, .6)',
            MI06: 'inset 0 0 .5em rgba(255, 255, 255, .6)',
            LI06: 'inset 0 0 .7em rgba(255, 255, 255, .6)',
            XLI06: 'inset 0 0 1em rgba(255, 255, 255, .6)',
            XXLI06: 'inset 0 0 1.7em rgba(255, 255, 255, .6)',
            XXXLI06: 'inset 0 0 3em rgba(255, 255, 255, .6)',
            // Inner white .4
            XXSI04: 'inset 0 0 .05em rgba(255, 255, 255, .4)',
            XSI04: 'inset 0 0 .1em rgba(255, 255, 255, .4)',
            SI04: 'inset 0 0 .2em rgba(255, 255, 255, .4)',
            MI04: 'inset 0 0 .5em rgba(255, 255, 255, .4)',
            LI04: 'inset 0 0 .7em rgba(255, 255, 255, .4)',
            XLI04: 'inset 0 0 1em rgba(255, 255, 255, .4)',
            XXLI04: 'inset 0 0 1.7em rgba(255, 255, 255, .4)',
            XXXLI04: 'inset 0 0 3em rgba(255, 255, 255, .4)',
            // Inner white .2
            XXSI02: 'inset 0 0 .05em rgba(255, 255, 255, .2)',
            XSI02: 'inset 0 0 .1em rgba(255, 255, 255, .2)',
            SI02: 'inset 0 0 .2em rgba(255, 255, 255, .2)',
            MI02: 'inset 0 0 .5em rgba(255, 255, 255, .2)',
            LI02: 'inset 0 0 .7em rgba(255, 255, 255, .2)',
            XLI02: 'inset 0 0 1em rgba(255, 255, 255, .2)',
            XXLI02: 'inset 0 0 1.7em rgba(255, 255, 255, .2)',
            XXXLI02: 'inset 0 0 3em rgba(255, 255, 255, .2)'
        },
        error: {
            main: '#ff2e49',
            light: '#e57373',
            dark: '#c62828',
        },
        warning: {
            main: '#ffa000',
            light: '#ffb74d',
            dark: '#f57c00',
        },
        info: {
            main: '#0288d1',
            light: '#4fc3f7',
            dark: '#01579b',
        },
        success: {
            main: '#388e3c',
            light: '#81c784',
            dark: '#2e7d32',
        },
    },
});
export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#f7f7f7',
            light: '#f0f0f0',
            dark: '#e9e9e9',
            contrastText: '#fff',
        },
        secondary: {
            main: '#f48fb1',
            light: '#f8bbd0',
            dark: '#c2185b',
            contrastText: '#000',
        },
        text: {
            primary05: 'rgba(255,255,255,.05) !important',
            primary10: 'rgba(255,255,255,.1) !important',
            primary15: 'rgba(255,255,255,.15) !important',
            primary20: 'rgba(255,255,255,.2) !important',
            primary25: 'rgba(255,255,255,.25) !important',
            primary30: 'rgba(255,255,255,.3) !important',
            primary35: 'rgba(255,255,255,.35) !important',
            primary40: 'rgba(255,255,255,.4) !important',
            primary45: 'rgba(255,255,255,.45) !important',
            primary50: 'rgba(255,255,255,.5) !important',
            primary55: 'rgba(255,255,255,.55) !important',
            primary60: 'rgba(255,255,255,.6) !important',
            primary65: 'rgba(255,255,255,.65) !important',
            primary70: 'rgba(255,255,255,.7) !important',
            primary75: 'rgba(255,255,255,.75) !important',
            primary80: 'rgba(255,255,255,.8) !important',
            primary85: 'rgba(255,255,255,.85) !important',
            primary90: 'rgba(255,255,255,.9) !important',
            primary95: 'rgba(255,255,255,.95) !important',
            primary: '#fff !important',

            contrast05: 'rgba(0,0,0,0.5) !important',
            contrast10: 'rgba(0,0,0,.1) !important',
            contrast15: 'rgba(0,0,0,.15) !important',
            contrast20: 'rgba(0,0,0,.2) !important',
            contrast25: 'rgba(0,0,0,.25) !important',
            contrast30: 'rgba(0,0,0,.3) !important',
            contrast35: 'rgba(0,0,0,.35) !important',
            contrast40: 'rgba(0,0,0,.4) !important',
            contrast45: 'rgba(0,0,0,.45) !important',
            contrast50: 'rgba(0,0,0,.5) !important',
            contrast55: 'rgba(0,0,0,.55) !important',
            contrast60: 'rgba(0,0,0,.6) !important',
            contrast65: 'rgba(0,0,0,.65) !important',
            contrast70: 'rgba(0,0,0,.7) !important',
            contrast75: 'rgba(0,0,0,.75) !important',
            contrast80: 'rgba(0,0,0,.8) !important',
            contrast85: 'rgba(0,0,0,.85) !important',
            contrast90: 'rgba(0,0,0,.9) !important',
            contrast95: 'rgba(0,0,0,.95) !important',
            contrast: '#000 !important',


            secondary: '#b0bec5',
            disabled: '#757575',
            hint: '#bdbdbd',
            contrastText: '#000',
        },
        bg: {
            primary05: 'rgba(0,0,0,.05) !important',
            primary10: 'rgba(0,0,0,.1) !important',
            primary15: 'rgba(0,0,0,.15) !important',
            primary20: 'rgba(0,0,0,.2) !important',
            primary25: 'rgba(0,0,0,.25) !important',
            primary30: 'rgba(0,0,0,.3) !important',
            primary35: 'rgba(0,0,0,.35) !important',
            primary40: 'rgba(0,0,0,.4) !important',
            primary45: 'rgba(0,0,0,.45) !important',
            primary50: 'rgba(0,0,0,.5) !important',
            primary55: 'rgba(0,0,0,.55) !important',
            primary60: 'rgba(0,0,0,.6) !important',
            primary65: 'rgba(0,0,0,.65) !important',
            primary70: 'rgba(0,0,0,.7) !important',
            primary75: 'rgba(0,0,0,.75) !important',
            primary80: 'rgba(0,0,0,.8) !important',
            primary85: 'rgba(0,0,0,.85) !important',
            primary90: 'rgba(0,0,0,.9) !important',
            primary95: 'rgba(0,0,0,.95) !important',
            primary: '#3f3f3f !important',

            contrast05: 'rgba(255,255,255,.05) !important',
            contrast10: 'rgba(255,255,255,.1) !important',
            contrast15: 'rgba(255,255,255,.15) !important',
            contrast20: 'rgba(255,255,255,.2) !important',
            contrast25: 'rgba(255,255,255,.25) !important',
            contrast30: 'rgba(255,255,255,.3) !important',
            contrast35: 'rgba(255,255,255,.35) !important',
            contrast40: 'rgba(255,255,255,.4) !important',
            contrast45: 'rgba(255,255,255,.45) !important',
            contrast50: 'rgba(255,255,255,.5) !important',
            contrast55: 'rgba(255,255,255,.55) !important',
            contrast60: 'rgba(255,255,255,.6) !important',
            contrast65: 'rgba(255,255,255,.65) !important',
            contrast70: 'rgba(255,255,255,.7) !important',
            contrast75: 'rgba(255,255,255,.75) !important',
            contrast80: 'rgba(255,255,255,.8) !important',
            contrast85: 'rgba(255,255,255,.85) !important',
            contrast90: 'rgba(255,255,255,.9) !important',
            contrast95: 'rgba(255,255,255,.95) !important',
            contrast: '#fff !important',

            secondary: '#6d6d6d',
            tertiary: '#b2b2b2',
            quaternary: '#ebebeb',
        },
        shadow: {
            // Outer .6
            XXSO06: '0 0 .05em rgba(0, 0, 0, .6)',
            XSO06: '0 0 .1em rgba(0, 0, 0, .6)',
            SO06: '0 0 .2em rgba(0, 0, 0, .6)',
            MO06: '0 0 .5em rgba(0, 0, 0, .6)',
            LO06: '0 0 .7em rgba(0, 0, 0, .6)',
            XLO06: '0 0 1em rgba(0, 0, 0, .6)',
            XXLO06: '0 0 1.7em rgba(0, 0, 0, .6)',
            XXXLO06: '0 0 3em rgba(0, 0, 0, .6)',
            // Outer .4
            XXSO04: '0 0 .05em rgba(0, 0, 0, .4)',
            XSO04: '0 0 .1em rgba(0, 0, 0, .4)',
            SO04: '0 0 .2em rgba(0, 0, 0, .4)',
            MO04: '0 0 .5em rgba(0, 0, 0, .4)',
            LO04: '0 0 .7em rgba(0, 0, 0, .4)',
            XLO04: '0 0 1em rgba(0, 0, 0, .4)',
            XXLO04: '0 0 1.7em rgba(0, 0, 0, .4)',
            XXXLO04: '0 0 3em rgba(0, 0, 0, .4)',
            // Outer .2
            XXSO02: '0 0 .05em rgba(0, 0, 0, .2)',
            XSO02: '0 0 .1em rgba(0, 0, 0, .2)',
            SO02: '0 0 .2em rgba(0, 0, 0, .2)',
            MO02: '0 0 .5em rgba(0, 0, 0, .2)',
            LO02: '0 0 .7em rgba(0, 0, 0, .2)',
            XLO02: '0 0 1em rgba(0, 0, 0, .2)',
            XXLO02: '0 0 1.7em rgba(0, 0, 0, .2)',
            XXXLO02: '0 0 3em rgba(0, 0, 0, .2)',
            // Inner .6
            XXSI06: 'inset 0 0 .05em rgba(0, 0, 0, .6)',
            XSI06: 'inset 0 0 .1em rgba(0, 0, 0, .6)',
            SI06: 'inset 0 0 .2em rgba(0, 0, 0, .6)',
            MI06: 'inset 0 0 .5em rgba(0, 0, 0, .6)',
            LI06: 'inset 0 0 .7em rgba(0, 0, 0, .6)',
            XLI06: 'inset 0 0 1em rgba(0, 0, 0, .6)',
            XXLI06: 'inset 0 0 1.7em rgba(0, 0, 0, .6)',
            XXXLI06: 'inset 0 0 3em rgba(0, 0, 0, .6)',
            // Inner .4
            XXSI04: 'inset 0 0 .05em rgba(0, 0, 0, .4)',
            XSI04: 'inset 0 0 .1em rgba(0, 0, 0, .4)',
            SI04: 'inset 0 0 .2em rgba(0, 0, 0, .4)',
            MI04: 'inset 0 0 .5em rgba(0, 0, 0, .4)',
            LI04: 'inset 0 0 .7em rgba(0, 0, 0, .4)',
            XLI04: 'inset 0 0 1em rgba(0, 0, 0, .4)',
            XXLI04: 'inset 0 0 1.7em rgba(0, 0, 0, .4)',
            XXXLI04: 'inset 0 0 3em rgba(0, 0, 0, .4)',
            // Inner .2
            XXSI02: 'inset 0 0 .05em rgba(0, 0, 0, .2)',
            XSI02: 'inset 0 0 .1em rgba(0, 0, 0, .2)',
            SI02: 'inset 0 0 .2em rgba(0, 0, 0, .2)',
            MI02: 'inset 0 0 .5em rgba(0, 0, 0, .2)',
            LI02: 'inset 0 0 .7em rgba(0, 0, 0, .2)',
            XLI02: 'inset 0 0 1em rgba(0, 0, 0, .2)',
            XXLI02: 'inset 0 0 1.7em rgba(0, 0, 0, .2)',
            XXXLI02: 'inset 0 0 3em rgba(0, 0, 0, .2)'
        },
        error: {
            main: '#ff2e49',
            light: '#e57373',
            dark: '#d32f2f',
        },
        warning: {
            main: '#ffa726',
            light: '#ffb74d',
            dark: '#f57c00',
        },
        info: {
            main: '#29b6f6',
            light: '#4fc3f7',
            dark: '#0288d1',
        },
        success: {
            main: '#66bb6a',
            light: '#81c784',
            dark: '#388e3c',
        },
    },
});

export default darkTheme;